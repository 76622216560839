.frame-child8 {
    position: relative;
    width: 18px;
    height: 9px;
  }
  
  .component-427-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-5xl) 0 270px;
  }
  
  .you-have-1-container1 {
    width: 100%;
  }
  
  .yesterday-1205-pm,
  .you-have-1-container {
    position: relative;
    letter-spacing: -0.2px;
    line-height: 21px;
    display: flex;
    align-items: center;
    width: 273px;
  }
  
  .yesterday-1205-pm {
    font-size: var(--tiny-10px-medium-size);
    color: var(--black-500-gray);
    width: 109px;
  }
  
  .you-have-1-request-from-the-us-parent {
    border-radius: var(--br-10xs) var(--br-10xs) 0 0;
    background-color: var(--white);
    border-bottom: 1px solid var(--color-gray-100);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-xl) var(--padding-xl) var(--padding-xs);
    gap: var(--gap-6xs);
  }
  
  .component-427,
  .component-428 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .component-428 {
    background-color: var(--white);
    border-bottom: 1px solid var(--color-gray-100);
    align-items: flex-start;
    padding: var(--padding-xs) var(--padding-xl);
    gap: var(--gap-6xs);
  }
  
  .see-all-notifications {
    position: relative;
    line-height: 21px;
    font-weight: 500;
  }
  
  .component-426,
  .component-427-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .component-426 {
    border-radius: 0 0 var(--br-10xs) var(--br-10xs);
    background-color: var(--white);
    flex-direction: row;
    padding: var(--padding-xs) 183px var(--padding-xs) var(--padding-xl);
  }
  
  .component-427-parent {
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    font-size: var(--text-xs-medium-size);
    color: var(--color-darkslategray-100);
    font-family: var(--small-12px-medium);
  }
  
  .w-full {
    width: 100%;
  }
  
  .notification-dropdown {
    position: absolute;
    top: 100%;
    right: 7.5%;
    z-index: 99;
    min-height: 253px;
  }
  
  .notification-dropdown .notification-itm {
    cursor: pointer;
  }
  
  .notification-dropdown .notification-itm:hover {
    background-color: var(--primary-100);
  }