body {
  margin: 0;
  line-height: normal;
  font-family: "sf-pro";
}

:root {
  /* fonts */
  --small-12px-medium: "sf-pro";
  --small-12px-regular: "sf-pro";
  --font-sf-pro-display: "sf-pro";
  --text-xs-medium: "sf-pro";
  --font-inter: "sf-pro";

  /* font sizes */
  --tiny-10px-medium-size: 10px;
  --body-16px-bold-size: 16px;
  --text-xs-medium-size: 12px;
  --font-size-sm: 14px;
  --font-size-smi: 13px;
  --font-size-5xs: 8px;
  --small-12px-medium-size: 12px;
  --font-size-base: 16px;
  --font-size-3xs: 10px;

  /* Colors */
  --color-whitesmoke-100: #f9f9f9;
  --black-200: #f5f5f5;
  --gray-200: #eaecf0;
  --black-300: #eaeaea;
  --white: #fff;
  --gray-500: #667085;
  --neutral-blue: #5d6f88;
  --helios-input-placeholder: rgba(93, 111, 136, 0.6);
  --color-slategray-100: rgba(93, 111, 136, 0.7);
  --color-darkslategray-100: #39424e;
  --trustegrity: #064765;
  --primary-100: #f6faff;
  --primary-50: #f9f5ff;
  --helios-light-blue: #c7d3e6;
  --helios-primary-blue: #246dcb;
  --black-400: #c4c4c4;
  --black-500-gray: #959393;
  --color-gray-100: rgba(0, 0, 0, 0.05);
  --color-gray-500: rgba(0, 0, 0, 0.1);
  --color-cornflowerblue-100: #7cb0f4;
  --primary-400: #59a0ff;
  --color-lavender: #dbe2ed;
  --primary-600: #7f56d9;
  --system-warning-300: #febb83;
  --system-danger-500-base: #f46363;
  --helios-status-colors-light-blue: #f2f7fe;
  --system-success-700: #1f9050;
  --system-danger-700: #9c3e3e;
  --primary-700: #01459d;
  --system-danger-600: #c54e4e;
  --neutral-white: #fff;
  --color-steelblue: #2b6e8c;
  --system-danger-300: #f89595;
  --green: #2ED477;
  --transparent: transparent;
  --granite-gray: #605F5F;

  /* Gaps */
  --gap-7xs: 6px;
  --gap-xl: 20px;
  --gap-62xl: 81px;
  --gap-xs: 12px;
  --gap-base: 16px;
  --gap-55xl: 74px;
  --gap-8xs: 5px;
  --gap-3xs: 10px;
  --gap-9xs: 4px;
  --gap-2xs: 11px;
  --gap-378xl: 397px;
  --gap-6xs: 7px;
  --gap-6xl: 25px;
  --gap-11xl: 30px;
  --gap-11xs: 2px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-6xs: 7px;
  --padding-12xs: 1px;
  --padding-3xs: 10px;
  --padding-473xl: 492px;
  --padding-39xl: 58px;
  --padding-11xl: 30px;
  --padding-10xs: 3px;
  --padding-7xs-5: 5.5px;
  --padding-64xl: 83px;
  --padding-base: 16px;
  --padding-5xl: 24px;
  --padding-sm: 14px;
  --padding-11xs: 2px;
  --padding-xs: 12px;
  --padding-6xs-5: 6.5px;
  --padding-6xl: 25px;
  --padding-9xl: 28px;
  --padding-21xl: 40px;
  --padding-lgi: 19px;
  --padding-7xs: 6px;

  /* Border radiuses */
  --br-10xs: 3px;
  --br-11xs: 2px;
  --br-9xs: 4px;
  --br-94xl: 113px;
  --br-6xs: 7px;
  --br-181xl: 200px;
}