.reset_modal .ant-modal-body {
    padding: 0 !important;
}

.reset_container {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
        width: 50%;
        padding: 24px;

        &:first-child {
            border-right: 1px solid #EAEAEA;
        }
    }

    h2 {
        color: $primary;
        font-size: 16px;
        font-weight: 700;
    }

    .group-inp {
        margin-top: 20px;
        width: 100% !important;
    }

    .fields_desc {

        ul {
            padding-left: 20px;
            li {
                color: $primary;
                font-size: 14px;
                font-weight: 500;
            }
        }
        

        .button_group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            padding-left: 20px;
        }

        .add-new-user-hover-edit {
            color: white;
            width: 100%;
            max-width: 104px;

            &.cancel_btn {
                background-color: transparent;
                color: $primary;
            }
        }
    }

    .validation {
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        & > div {
            width: 100%;
        }
    }
}