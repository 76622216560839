.hel-top-navbar {
    box-shadow: $box-shadow-bottom;
    margin: 0 !important;
    min-height: 61px;
    gap: 20px;
    
    .navbar-list { 
        li img {
            width: 25px; 
            height: 25px;
            
            &.avatar-40 {
                width:40px;
                height: 40px;
                border: 2px solid $header-avatar-border;
            }
    
            &.indicator {
                width: initial;
                height: initial;
                position: absolute;
                bottom: 10px;
                right: 5px;
            }
        }
    }

    .select-container {
        max-width: 260px;
    }

    .input-group-text {
        padding: 0 !important;
    }

    .hel-sub-dropdown {
        background: #FFFFFF;
        box-shadow: 9px 0px 37px rgb(0 0 0 / 23%);
    }

    .hel-sub-card {
        padding: 30px 40px;
    }

    .password {
        &-title {
            font-weight: 300;
            color: black;
        }

        &-cancel {
            border: none;
            color: $primary;
            background-color: $btn-bg-blue-light;
            font-size: .9rem;
            border-radius: 3px;
        }
        
        &-update {
            background-color: $btn-bg-blue-dark;
            border-radius: 3px;
            font-size: .9rem;

            img{
                width: auto !important;
                height: auto !important;
            }
        }
    }

    @media (max-width: 768px) {
        gap: 10px;
    }

}

.trustegrity_img {
    max-width: 121px;

    @media (max-width: 768px) {
        max-width: 80px;
    }
}

.find_btn {
    border-radius: 2px;
    border: 1px solid #064765;
    background: #064765;
    color: #ffffff;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    width: 100%;
    max-width: 140px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    svg {
        margin-right: 6px;
    }
}