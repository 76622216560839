.label-container {
    height: 61px;
    display: flex;
    padding: 0 var(--padding-3xs);
    align-items: center;
    justify-content: space-between;
}

.show-page {
    display: flex;
    gap: var(--gap-3xs);
    text-align: left;
    font-size: 0.75rem;
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
    flex-direction: row;
    align-items: baseline;
}

.past-connection-frame {
    overflow-y: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-3xs) var(--padding-xl);
    box-sizing: border-box;
    gap: 10px 0px;

    @media (max-width: 767px) {
        padding: 0;
    }
}

.past-tabel {
    box-shadow: 0px 4px 4px var(--color-gray-100);
    width: 100%;
    border-radius: var(--br-10xs);
}

table {
    display: table;
    width: 100%;
}

th,
td {
    padding: 0 var(--padding-sm);
}

th {
    cursor: pointer;
    font-weight: bold;
}

thead,
tr {
    background-color: var(--black-300);
    height: 40px;
    font-family: var(--small-12px-medium);
    font-size: 0.5625rem;
    color: var(--trustegrity);
    border-right: 1px solid var(--black-300);
    border-bottom: 1px solid var(--black-300);
    border-left: 1px solid var(--black-300);
}

.connecton-row {
    font-family: var(--small-12px-medium);
    font-size: 0.75rem;
    background-color: var(--white);
    color: $meteorite-gray;
    letter-spacing: 0.3px;
    font-weight: 500;
    cursor: pointer;
}

.arrow-icon {
    height: 6px;
    margin-left: 3px;
    fill: var(--trustegrity);
}

.to-text {
    color: var(--trustegrity);
}

.text-desc {
    font-weight: 400;
    width: 130px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.unread {
    font-family: var(--small-12px-medium);
    font-size: 0.75rem;
    color: var(--trustegrity);
    background-color: var(--black-200);
    color: $meteorite-gray;
    letter-spacing: 0.3px;
    font-weight: 500;
    cursor: pointer;
}

.past-footer {
    border-radius: 0px 0px var(--br-10xs) var(--br-10xs);
    background-color: var(--white);
    border-right: 1px solid var(--black-300);
    border-bottom: 1px solid var(--black-300);
    border-left: 1px solid var(--black-300);
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-xl);
    gap: 5px;
    text-align: center;
    font-size: 0.8rem;
    color: var(--trustegrity);
    line-height: 1.5rem;
    font-family: var(--small-12px-medium);
    text-transform: uppercase;
}

.first-last {
    border-radius: var(--br-11xs);
    border: 1px solid var(--black-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 18px;
    font-weight: 500;
    background-color: var(--white);
}

.first-last:disabled{
    opacity: .5;
}

.past-per-page-list {
    .ant-select-selector {
        border: none !important;
        background-color: var(--color-whitesmoke-100) !important;
        box-shadow: none !important;
        font-size: 0.75rem !important;
        color: var(--trustegrity) !important;
        font-family: var(--small-12px-medium) !important;
        font-weight: 600 !important;
    }
}

.icon-more {
    height: 14px;
    width: 14px;
}

.action-container {
    overflow: hidden;
    text-align: left;
    font-size: 0.875rem;
    color: var(--color-darkslategray-100);
    font-family: var(--small-12px-medium);
    border-radius: var(--br-10xs);
}

.action-item {
    background-color: var(--white);
    box-shadow: 0px 4px 4px var(--color-gray-500);
    border: 1px solid var(--black-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-3xs) 40px var(--padding-3xs) var(--padding-3xs);
    cursor: pointer;
    color: var(--color-darkslategray-100);
}

.action-item:hover {
    background-color: var(--helios-primary-blue);
    border: 1px solid var(--helios-primary-blue);
    color: var(--white);

    svg {
        fill: var(--white);
    }
}

.action-icon {
    height: 17px;
    width: 17px;
}

.ant-select-item-option-selected {
    background-color: var(--helios-primary-blue) !important;
    color: var(--white) !important;
}

.pagination-main {
    list-style-type: none;
    border: 1px solid var(--black-300);
    border-radius: var(--br-9xs);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 0.625rem;
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
}

.icon-prev {
    height: 36px !important;
    width: 36px !important;
    border-right: 1px solid var(--black-300);
}

.icon-next {
    height: 36px;
    width: 36px;
}

.page-item {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--black-300);
}

.active-page {
    background-color: var(--black-300);
    font-weight: 600;
}

.popup-menu {
    position: absolute;
    display: flex;
    top: 0px;
    right: 38px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: max-content;
}

.disable-pagination {
    svg {
        opacity: .5;
    }
}

.connecton-row,
.unread {
    outline: 1px solid transparent;
    transition: outline 200ms ease-in-out;
}

.connecton-row:hover,
.unread:hover {
    outline: 1px solid $meteorite-gray;
    cursor: pointer;
}

.switch-label{
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 0.625rem;
    color: var(--trustegrity);
}