.label-container {
    height: 61px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;

    .ant-segmented {
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
        border-radius: 23.5px;
        background-color: $white;
        padding: 0;
    }

    .ant-segmented-group {
        label {
            margin-bottom: 0 !important;
        }
    }

    .ant-segmented-item-selected {
        box-shadow: none;
        border-radius: 23.5px;
        border-radius: 23.5px;
        background-color: $primary;
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        line-height: 120%;
        color: $white !important;
        text-align: center;
    }

    .ant-segmented-item {
        font-size: 0.625rem;
        letter-spacing: 0.3px;
        line-height: 120%;
        color: $natural-blue;
        padding: 0 5px;
    }

    .ant-segmented-item-label {
        font-weight: bold;
    }

    .ant-segmented:not(.ant-segmented-disabled):hover {
        background-color: $white;
    }

    .ant-segmented-thumb {
        background-color: $primary ;
        border-radius: 23.5px;
    }
}

.connection-header-label {
    color: var(--bs-body-color);
    font-size: 1rem;
    line-height: 1.75rem;
    font-family: var(--small-12px-medium);
    font-weight: 700;
    font-style: normal;
    margin-bottom: 0px;
}

.connection-poster {
    width: 100%;
    min-width: 188px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: var(--black-300);
    height: 100%;
}

.connection-frame {
    // overflow-y: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 10px 0px !important;
    box-sizing: border-box;
    gap: 10px 0px;
    // width: 881px;
    width: 100%;
    min-width: 881px;
}

.container-content {
    background-color: var(--white);
    box-shadow: 0px 4px 4px var(--color-gray-100);
    width: 100%;
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid var(--black-300);
    min-height: 360px;
}

.referal-labeal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 20px;
}

.referral-text-parent {
    border-bottom: 1px solid var(--color-darkslategray-100);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0px 10px;
    gap: 0px 15px;
    font-family: var(--small-12px-medium);
}

.referral-text-parent,
.date {
    font-size: 0.75rem;
    letter-spacing: 0.3px;
    line-height: 1.25rem;
    font-weight: 600;
    color: var(--color-darkslategray-100);
}

.referral-text-col {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.referral-text {
    font-size: 1rem;
    color: var(--trustegrity);
    text-align: left;
}

.connection-description {
    border-radius: 0px var(--br-10xs) var(--br-10xs) 0px;
    background-color: var(--white);
    border-left: 1px solid var(--black-300);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 21px 20px 20px 33px;
    min-height: 415px;
}

.description-text {
    flex-shrink: 0;
    font-size: 0.75rem;
    letter-spacing: -0.5px;
    line-height: 140%;
    font-weight: 500;
    font-family: var(--small-12px-medium);
    color: var(--trustegrity);
    text-align: left;
    opacity: 0.8;
}

.group-child {
    padding: 10px 30px;
    width: 100%;
    max-width: 100%;
}

.refferal-form {
    display: block;
    width: 100%;
    padding: 15px 0px 0px 0px;
}

.connection-name-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 5px 0px;
    gap: 3px 0px;
    
    flex: 1;
    position: relative;
}

.border-none {
    border: none;
}

.form-row-ref {
    display: flex;
    justify-content: space-between;
    align-items:start;
    width: 100%;
    padding: 0px 0px 15px 0px;
    gap: 15px;
    flex-direction: row;
}

.connection-frame-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
    padding: 5px 6px;
    gap: 10px;
}

.connection-frame-group,
.user-title {
    font-weight: 500;
}

.connection-frame-group,
.user-to {
    font-weight: 600;
    border: none;
}

.connection-frame-input {
    gap: 0px 10px;
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
    width: 100%;
    .connection-input{
        padding: 3px 0px;
        border-bottom: 1px solid var((--black-300));
    }
}

.connection-name-parent-to{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 5px 0px;
    gap: 3px 0px;
    border-bottom: 1px solid var((--black-300));
    flex: 1;
    position: relative;
    width: 100%;
}

.connection-frame-input-to{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 3px 6px;
    gap: 0px 10px;
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
    width: 100%;
}

.connection-frame-input,
textarea {
    width: 100%;
    outline: none;
}

.user-img-con {
    width: 24px;
    position: relative;
    border-radius: 32.73px;
    height: 24px;
    object-fit: contain;
    background-color: var(--black-400);
}

.user-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.arrow-down {
    width: 16px;
    height: 9.45px;
    object-fit: contain;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.connection-input {
    border: 0;
}

.connection-input,
input {
    width: 100%;
}

.send-connection-button {
    background-color: var(--trustegrity);
    border: 1px solid var(--trustegrity);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--white);
    border-radius: var(--br-10xs);
    font-size: 0.8rem;
    font-family: var(--small-12px-medium);
    align-self: flex-end;
    padding: 10px 20px;
    min-width: 130px;
    width: auto;
}

.members-select {
    position: absolute;
    width: 100%;
    height: auto;
    overflow: auto;
    background-color: var(--white);
    top: 60px;
    z-index: 9999;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: var(--br-10xs);
    max-height: 550px;
}

.to-title {
    background-color: var(--black-300);
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.875rem;
    line-height: 120%;
    text-transform: uppercase;
    color: var(--trustegrity);
    display: flex;
    width: 100%;
}

.member-item {
    border-bottom: 1px solid var(--helios-status-colors-light-blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    color: var(--trustegrity);
    font-family: var(--small-12px-medium);
    font-weight: 400;
    font-size: 0.75rem;
    gap: 10px;
    cursor: pointer;
}

.error-label {
    font-size: 0.5625rem;
    margin-bottom: 0;
    color: var(--system-danger-500-base);
}

.input-bottom {
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.input-bottom .name1 {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 1px;
}

.input-item {
    display: block;
    flex: 1;
}

.form-row-ref .input-item:hover .name1, .form-row-ref .connection-input:focus + .input-bottom .name1 {
    opacity: 1;
}

.thank-you-container {
    position: relative;
    border-radius: var(--br-10xs);
    background-color: var(--white);
    box-shadow: 0px 4px 10px var(--color-gray-100);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 70px 64px;
    box-sizing: border-box;
    gap: 30px 0px;
    text-align: right;
    font-size: 3rem;
    color: var(--transparent);
    font-family: var(--small-12px-medium);
}

.thank-you-label {
    line-height: 100%;
    font-weight: 800;
    display: flex;
    align-items: center;
    -webkit-text-stroke: 1px var(--trustegrity);
}

.thank-you-for-container {
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 45px;
    flex-shrink: 0;
}

.thank-you-for-container1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: 1rem;
    color: $black;
    line-height: 140%;
}

.thank-you-for {
    margin: 0;
    font-weight: 600;
    letter-spacing: -0.5px;
}

.send-new-button {
    background-color: var(--white);
    border: 1px solid var(--trustegrity);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--trustegrity);
    border-radius: var(--br-10xs);
    font-size: 0.8rem;
    font-family: var(--small-12px-medium);
    align-self: flex-end;
    padding: 10px 20px;
}

@media (max-width:1024px) {
    .md-w-full {
        width: 100%;
    }
    .profile-info {
        .md-flex-wrap{
            flex-direction: unset !important;
            gap:10px;
            height: auto;
            .show-page{
                min-width: 60%;
                justify-content: end;
            }
        }
    }
}

.return-group {
    color: var(--trustegrity);
    font-size: 0.8rem;
    line-height: 120%;
    font-weight: 600;
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 13px;
        width: 13px;
        fill: var(--trustegrity)
    }
}

.return-group,
a:active,
a:hover {
    color: var(--trustegrity) !important;
}

@media (max-width:767px) {
    .connection-poster-wrap {
        display: none;
    }

    .container-content {
        flex-direction: column-reverse;
    }

    .connection-description {
        min-height: auto;
        padding: 15px;
    }

    .description-text {
        height: auto;
    }

    .connection-frame {
        min-width: 100%;
        padding: 0 !important;
    }

    .description-text {
        width: auto;
    }

    .content-page {
        padding-top: 63px !important;
    }

    .thank-you-container {
        padding: 30px 20px;
        font-size: 2rem;
    }
    .profile-info {
        .md-flex-wrap {
            .show-page{
                min-width: 50%;
            }
        }
    }
}
@media (max-width:1156px) {
    .connection-frame {
        min-width: 100%;
        padding: 0 !important;
    }
}

@media (max-width:577px) {
    .form-row-ref {
        flex-direction: column;
    }

    .connection-frame {
        .connection-name-parent .connection-name-parent-to {
            justify-content: flex-start;
            flex: unset;
            width: 100%;
            flex-direction: column;
        }

        .input-item {
            flex: unset;
            width: 100%;
        }
    }

    .profile-info {
        .label-container {
            width: 100%;
            align-items: center !important;
            height: auto;
            margin-top: 7px;
        }
    }

    .thank-you-container {
        .send-new-button {
            text-align: center;
        }

    }

    .members-select{
        max-height: 420px;
    }
}

.connection-container {
    width: 100%;
    max-width: 900px;
    margin: 15px;
    margin-top: initial;

    .label-container {
        justify-content: flex-start !important;
        gap: 8px;
        height: auto;
        padding: 17px 10px;
    }
}
.to-input{
    height: 26px;
    display: flex;
    justify-content: center;
    width: 95%;
}
@media (max-width:375px){
    .profile-info {
        .md-flex-wrap {
            .show-page {
                justify-content: start;
            }
        }
    }
}