*::-moz-selection {
    background: #0084ff;
    color: #ffffff;
    text-shadow: none;
}

::-moz-selection {
    background: #0084ff;
    color: #ffffff;
    text-shadow: none;
}

::selection {
    background: #0084ff;
    color: #ffffff;
    text-shadow: none;
}

body {
    font-weight: 400;
    font-style: normal;
    font-size: .9rem;
    line-height: 1.8;
    padding: 0;
    margin: 0;
    color: #777D74;
    background: #f3f7fd;
}

a,
.btn {
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    text-decoration: none;
    cursor: pointer;
}

a,
button,
input,
btn {
    outline: medium none !important;
}

.uppercase {
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    margin: 0px;
    line-height: 1.5;
    color: #374948;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

label {
    font-weight: normal;
}

h1 {
    font-size: 3.052em;
}

h2 {
    font-size: 2.300em;
}

h3 {
    font-size: 1.953em;
}

h4 {
    font-size: 1.300em;
}

h5 {
    font-size: 1.200em;
}

h6 {
    font-size: 1.0em;
}


.notification-container {
    top: 60px !important;
    overflow: unset !important;
}

.notification-container .notification {
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16) !important;
    background: #fff !important;
    opacity: 1;
}

.notification-container .notification .title {
    position: relative;
    font-size: var(--table-head-column-name-size);
    letter-spacing: 0.5px;
    line-height: 21px;
    text-transform: uppercase;
    font-family: var(--table-head-column-name);
    color: var(--helios-input-placeholder);
    text-align: left;
}

.notification-container .notification .message {
    position: relative;
    font-size: var(--table-list-values-size);
    letter-spacing: 0.3px;
    line-height: 20px;
    font-weight: 500;
    font-family: var(--table-list-values);
    color: var(--neutral-blue);
    text-align: left;
}

.notification-container .notification-success:before {
    color: var(--green);
}

.notification-container .notification-error:before {
    color: #f44336;
}