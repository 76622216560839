body {
    .tool-title {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 24px;
        color: $primary-dark;
    }

    .dashed {
        @include borderLine(1px, dashed, $border-blue);
    }

    .gap-content {
        height: 250px;
        width: 100%;
        margin: auto;
        text-align: center;
        border-radius: 2px;
    }

    .right-tool-box {
        height: 35px;
        width: 100%;
        border-radius: 2px;
        @include borderLine(0.5px, dashed, #000);
        background-color: $gray-bg;
    }
    
    .right-tool-bottom-box {
        width: 100%;
        border-radius: 2px;
        @include borderLine(0.5px, dashed, #000);
        background-color: $gray-bg;
    }

    input, select {
        &:focus {
            background-color: #E5FFF0 !important;
        }
    }

    .file_uploader {
        width: 120px;
        height: 120px;
        min-width: 120px;
        background-image: url('../../images/user-avatar.png');
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            width: 80px;
            height: 80px;
            min-width: 80px;
        }
    }

    .group {
        .cover-image {
            height: 94px;
            width: 100%;
            background-image: url('../../images/cover-image.png');
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 3px 3px 0px 0px;
            position: relative;

            .file_uploader {
                position: absolute;
                top: 25px;
                left: 20px;

                @media (max-width: 768px) {
                    top: 50px;
                }
            }

            .cover-upload {
                position: absolute;
                right: 20px;
                top: 10px;
                cursor: pointer;
            }
        }

        .information {
            margin-top: 20px;
            padding-left: 10px;
            //padding-left: 170px; ADD BACK WHEN GROUP IMAGE IS ADDED

            .name {
                color: $bright-gray;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                position: relative;

                & + span {
                    color: $bright-gray;
                    font-size: .9rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 0.3px;
                }
            }

            
            @media (max-width: 768px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        
        .report-title {
            overflow: hidden;
            color: rgba(93, 111, 136, 0.60);
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .9rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            & + span {
                overflow: hidden;
                color: $bright-gray;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: .9rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }

        @media (max-width: 540px) {

            flex-direction: column;
            align-items: center;
            text-align: left;

            & > * {
                width: 100%;
            }

            .report-title, .report-title+span {
                justify-content: flex-start;
                white-space: unset;
                margin-left: 0 !important;
                gap: 2px;
                text-align: left;
                align-items: flex-start;
                svg{
                    height: 30px;
                    width: 20px;
                }
            }
        }

        .meeting {
            min-height: 48px;
            margin-top: 40px;
            padding: 10px 20px;
            width: 100%;
            border-radius: 3px;
            border: 1px solid DRAG_GENERAL_BACKGROUND;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

            .title {
                color: #246DCB;
                font-size: .9rem;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.3px;
            }
        }

        &_chair {
            margin-top: 20px;
            border-radius: 3px;
            border: 1px solid #EAEAEA;
            background: #FFF;
            padding: 20px;

            & > div {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 20px;

                @media (max-width:868px) {
                    grid-template-columns: auto;
                }

            }

            h1 {
                color: $primary;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }

        }

        .functionalities {
            margin-top: 20px;
            margin-bottom: 20px;

            .export + .btn {
                width: 190px;
                height: 34px;
            }
        }

        &-content {
            background: #fff;
            box-shadow: $box-shadow-retangle;
        }

        &-type {
            &-group {
                label  {
                    font-size: .9rem;
                    padding: 7px 15px;
                    border: 1px solid rgba(8, 94, 208, 0.4);
                    color: $primary !important;
                    border-radius: 76px;
                    transition: all 0.3s ease-out 0s;

                    img {
                        width: 11px;
                        display: none;
                        opacity: 0;
                    }
                }

                .btn-check:checked + .btn {
                    background-color: #E5FFF0;
                    border: none !important;
                }

                .btn-check:checked  + .btn img {
                    opacity: 1;
                    display: initial;
                }
            }

            .btn-check:focus + .btn, .btn:focus {
                box-shadow: none !important;
            }

            .or-divider {
                text-align: center;
                line-height: 3;
            }

            .add-client {
                background-color: #EBF4FF;
                filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.04)) drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.16));
            }

        }

    }

    .cardetail-select {
        background-color: #E5FFF0 !important;
        border: 1px solid #27B264 !important;
    }

    .image-select {
        .css-1okebmr-indicatorSeparator {
            background-color: white;
        }

        .css-9x2jh-control {
            border: none !important;
        }

        #react-select-3-input {
            &:focus {
                .image-select span {
                    background-color: #E5FFF0;
                }
            }
        }

        div {
            margin: 0 !important;
        }
    }

    .color-group {
        & > div {
            width: 20%;
            @media (max-width: 990px) {
                width: 50%;
            }
        }
    }

    .mileage-group {
        span {
            color: #000;
            font-size: .9rem;
            line-height: 3;
        }
    }

    .custom-select {
        width: 113px;
        height: 29px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        background-size: .6em;
        background-position: calc(100% - 0.8em) center;
        background-repeat: no-repeat;
    }

    .map_search-form {
        z-index: 1;
        border-radius: 3px;
        background: #FFF;
        display: flex;
        padding: 8px;
        align-items: center;
        max-width: 630px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

        @media (max-width: 768px) {
            width: 100%;
            padding: 4px;

            .ant-divider-vertical {
                display: none !important;
            }

            .ant-select {
                width: 100% !important;
                max-width: 150px;
            }
            
            .near_me {
                height: 42px;
                max-width: 150px;
                width: 100% !important;
                padding: 0 11px;

                span {
                    display: flex;
                    input {
                        color: $black;
                        font-size: 0.813rem;
                        font-weight: 400;
                        line-height: 120%;
                        border: none;
                        border-bottom: 1px solid $gray-bg;
                        box-shadow: none;
                        width: auto;
                    }
                    a {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                @media (max-width: 768px) {
                    max-width: 95px !important;
                    display: flex;
                    align-items: center;
                    margin-left: 0 !important;
                }
            }
        }

        .input-group {
            display: flex;
            align-items: center;

            input {
                color: #959393;
                font-size: 0.813rem;
                font-style: italic;
                font-weight: 400;
                line-height: 120%;
                border: none;
                box-shadow: none;
                outline: none;
                height: 34px;
                width: 90%;
            }

            @media (max-width: 768px) {
                svg {
                    display: none;
                }
            }
        }

        button {
            border-radius: 2px;
            border: 1px solid $primary;
            background: $primary;
            color: #ffffff;
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            width: 100%;

            @media (min-width: 769px) {
                padding: 7px 15px;
                max-width: 91px;

                img {
                    display: none;
                }
            }

            @media (max-width: 768px) {
                max-width: 35px;
                padding: 4px 7px;
                height: 35px;

                img {
                    display: block;
                    width: 20px;
                }

                span {
                    display: none;
                }
            }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: #fff !important;
        }

        .ant-select-selection-item {
            font-weight: 500 !important;
        }

        .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
            color: $primary;
        }

        .ant-select-arrow .anticon > svg {
            fill: $primary;
        }

        .near_me {
            width: 150px;
            white-space: nowrap;
            margin-left: 18px;
            margin-right: 18px;

            input {
                color: $black;
                font-size: 0.813rem;
                font-weight: 400;
                line-height: 120%;
                border: none;
                border-bottom: 1px solid $gray-bg;
                box-shadow: none;
                width: auto;
            }
            a {
                span {
                    font-size: 0.75rem;
                }
            }
        }

    }

    .finder-board {
        width: 100%;
        height: 90vh;
        
        .title {
            font-size: 1rem;
            font-weight: 900 !important;
            line-height: 24px;
            color: $primary-dark;
            margin: 0 !important;
            margin-right: 20px !important;
        }

        @media (max-width: 768px) {
            height: 100vh;
        }
    }

}

.profile-link {
    cursor: pointer;
}

.group_finder {
    color: black;
}

body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: inherit !important;
}

.distance_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    
    button {
        border-radius: 2px;
        border: 1px solid $primary;
        background: $primary;
        color: #ffffff;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        padding: 7px 7px;
    }

    .ant-slider {
        margin-left: auto;
        width: 100%;
    }
}

.ant-popover {
    width: 500px;
}

.ant-popover-inner-content {
    width: 100% !important;
    margin-top: 20px;
}

.ant-popover-title {
    font-size: 0.75rem;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: start;
}

.ant-tooltip {
}

.ant-slider-handle.ant-tooltip-open, .ant-slider-handle {
    border: $primary !important;
    background-color: $primary !important;
}

.ant-slider-track {
    background-color: gray !important;
}

.ant-tooltip-inner {
    background-color: #ffffff !important;
    border-radius: 5px;
    font-size: 0.75rem;
}

.ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(to right bottom, #ffffff, #ffffff) !important;
}

.ant-select-dropdown .ant-select-item-option:hover,
.ant-select-dropdown .ant-select-item-option-active {
    background-color: $primary !important;

    .ant-select-item-option-content {
        color: var(--white) !important;
    }
}

.googleColumn {
    @media (max-width: 1024px) {
        width: 100% !important;
        height: 35vh;
    }
    @media (max-width: 768px) {
        .gm-style-mtc-bbw {
            display: none;
        }
    }
}
.googleColumn > div:first-of-type{
    height: calc(100vh - 140px) !important;
}

.leftColumn {
    opacity: 0;
    z-index: -1;
    width: 50%;
    transition: width 0.5s ease;
    margin-top: 6px;
    position: relative;

    &.show {
        opacity: 1;
        z-index: 111;
        background-color: white;
    }

    @media (max-width: 1024px) {
        height: 60vh;
        width: 100%;
        order: 2;
    }
}

.map-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
}

.column_close {
    position: absolute;
    top: 0;
    right: -7px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.group-results {
    border-radius: 3px;
    width: 100%;
    max-width: 0;
    overflow-y: auto;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 11111;
    height: calc(100vh - 150px);

    &.visible {
        transform: translateX(0);
        max-width: 100%;
        transition: transform 0.3s ease-in-out;

    }

    .item {
        border-radius: 3px 0px 0px 0px;
        border-bottom: 1px solid #EAEAEA;
        border-left: 1px solid #EAEAEA;
        background: #FFF;
        padding: 20px;
        width: 100%;
        gap: 12px;

        & > div {
            width: 100%;
        }

        .content {
            &-left {
                width: 66.66%;
                h3 {
                    color: $primary;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                }
        
                span {
                    color: $bright-gray;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 0.8rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                }
            }
            &-right{
                width: 33.33%;
                display: flex;
                justify-content: flex-end;
            }

            &-center {
                font-size: 0.625rem !important;
                .label {
                    color: $bright-gray;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 0.8rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 12px */
                }

                .value {
                    color: $bright-gray;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 0.8rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }

                .title-event{
                    font-size: 0.9rem;
                    line-height: 140%;
                    font-weight: 600;
                    color: var(--color-darkslategray-100);
                    text-align: left;
                }

                & > div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        @media (max-width: 470px) {
            .content-center{
                flex-direction: column !important;
                gap: 10px;
            }
            .content-right{
                width: 100%;
                justify-content: center;
            }
            .content-left{
                width: 100%;
            }
        }
    }

    @media (max-width: 1024px) {
        max-height: calc(65vh - 53px);
    }
}



.map-tooltip {
    &_wrap {
        width: 170px;
    }

    &_name {
        color: #064765;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 18.2px;
    }

    &_address {
        color: #39424E;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.2em;
        margin-top: 20px;
    }

    &_btn {
        color: #C4C4C4;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2em;
    }
}

.welcome_next-meeting {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
    @media (max-width:992px){
        display: flex;
        flex-direction: column;
    }
    & > div {
        padding: 20px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        grid-gap: 20px;

        strong {
            color: #064765;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 100%;   
    }
}

.card_title {
    color: #064765;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.bg-filled-btn {
    display: flex;
    width: 140px;
    height: 30px;
    padding: 7px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--br-10xs);
    border: 1px solid $primary;
    background: $primary;
    color: #FFF !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin: auto;
}

.bg-outted-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--helios-light-blue);
    gap: 10px;
    margin: auto;
    width: 140px;
    height: 34px;
    border-radius: var(--br-10xs);
    color: $bright-gray;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
}

.fade-out {
    animation-name: fadeOut;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}
.ant-spin-container {
    .item {
        &.active{
            animation: colors 10s infinite;
        }
    }
}
@keyframes colors {
    0% { background-color: #f2f7fe; }
    25% { background-color: rgba(242,247,254,50%); }
    50% { background-color: rgba(242,247 ,254, 30%); }
    75% { background-color: rgba(242,247,254,10%); }
    100% { background-color: transparent}
}

.social-icon{
    height: 14px;
    width: 14px;
}

.main-section{
    &.menu-pinned{
        @media (max-width:1200px){
            .head{
                flex-direction: column !important;
                gap: 10px;
                .referral {
                    align-self: center;
                }
            }
            .welcome_next-meeting {
                display: flex;
                flex-direction: column;
            }
        }
    }

}
