@font-face {
    font-family: saira-medium;
    src: url("../../fonts/SFProText/SF-Pro-Text-Medium.otf") format('truetype');
}

@font-face {
    font-family: saira-regular;
    src: url("../../fonts/SFProText/SF-Pro-Text-Regular.otf") format('truetype');
}

@font-face {
    font-family: saira-thin;
    src: url("../../fonts/SFProText/SF-Pro-Text-Thin.otf") format('truetype');
}

@font-face {
    font-family: 'sf-pro';
    src: url("../../fonts/SFProText/SF-Pro-Text-Thin.otf") format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: 'sf-pro';
    src: url("../../fonts/SFProText/SF-Pro-Text-Light.otf") format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'sf-pro';
    src: url("../../fonts/SFProText/SF-Pro-Text-Regular.otf") format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'sf-pro';
    src: url("../../fonts/SFProText/SF-Pro-Text-Medium.otf") format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'sf-pro';
    src: url("../../fonts/SFProText/SF-Pro-Text-Semibold.otf") format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'sf-pro';
    src: url("../../fonts/SFProText/SF-Pro-Text-Bold.otf") format('opentype');
    font-weight: 700;
}


.saira-medium {
    font-family: 'saira-medium' !important;
}

.saira-regular {
    font-family: 'saira-regular' !important;
}

.saira-semibold {
    font-family: 'saira-semibold' !important;
}

.saira-thin {
    font-family: 'saira-thin' !important;
}

.saira-light {
    font-family: 'saira-light' !important;
}

.saira-extralight {
    font-family: 'saira-extralight' !important;
}

.saira-extrabold {
    font-family: 'saira-extrabold' !important;
}

.saira-bold {
    font-family: 'saira-bold' !important;
}

.sf-pro-medium {
    font-family: 'sf-pro-medium' !important;
}

.sf-pro-regular {
    font-family: 'sf-pro-regular' !important;
}

.sf-pro-semibold {
    font-family: 'sf-pro-semibold' !important;
}

.sf-pro-thin {
    font-family: 'sf-pro-thin' !important;
}

.sf-pro-light {
    font-family: 'sf-pro-light' !important;
}

.sf-pro-bold {
    font-family: 'sf-pro-bold' !important;
}
